import Vue from 'vue'

export default {
  SET_CHILDREN(state, childrenList) {
    state.childrenList = childrenList
  },
  SET_CHILDREN_COUNT(state, count) {
    state.childrenCount = count
  },
  child_added(state, child) {
    state.childrenList.push(child)
  },

  SET_CHILDREN_GROUPS(state, groups) {
    state.childrenGroups = groups
  },
  childrenGroupAdded(state, group) {
    state.childrenGroups.push(group)
  },
  SET_WORKERS(state, workersList) {
    state.workersList = workersList
  },
  workerAdded(state, worker) {
    state.workersList.push(worker)
  },
  childEdited(state, child) {
    const index = state.childrenList.findIndex(
      (element) => element.id === child.id
    )
    Vue.set(state.childrenList, index, child)
  },
  workerEdited(state, worker) {
    const index = state.workersList.findIndex(
      (element) => element.id === worker.id
    )
    Vue.set(state.workersList, index, worker)
  },
  setWorkerToEdit(state, worker) {
    state.workerToEdit = worker
  },
  setChildToEdit(state, child) {
    state.childToEdit = child
  },
  emptyChildToEdit(state) {
    state.childToEdit = null
  },
  emptyWorkerToEdit(state) {
    state.workerToEdit = null
  },
  childrenGroupDeleted(state, id) {
    state.childrenGroups.splice(
      state.childrenGroups.findIndex((g) => g.id === id),
      1
    )
  },
  // childrenGroupUpdated(state, id) {
  //   const index = state.childrenGroups.findIndex(g => g.id === id);
  //   state.childrenGroups[index] = state;
  // },
  sectionDeleted(state, id) {
    state.sections.splice(
      state.sections.findIndex((s) => s.id === id),
      1
    )
  },

  SET_WORKERS_SCHEDULER(state, workersSchedulerList) {
    console.log({ workersSchedulerList })
    state.workersSchedulerList = workersSchedulerList
  },

  SET_WORKER_FILES(state, workerFiles) {
    state.workerFiles = workerFiles
  },
  APPEND_WORKER_FILES(state, workerFiles) {
    state.workerFiles.push(...workerFiles)
  },
  SET_VISIT_SCHEDULER(state, visitSchedulerList) {
    state.visitSchedulerList = visitSchedulerList
  },
  visit_scheduler_added(state, visit) {
    state.visitAdditionalList.push(visit)
  },
  SET_VISIT_ADDITIONAL_SCHEDULER(state, visitSchedulerList) {
    state.visitSchedulerList = visitSchedulerList
  },
  visit_additional_scheduler_added(state, visit) {
    state.visitSchedulerList.push(visit)
  },
  SET_VISIT(state, visitList) {
    console.log({ visitList })
    for (let group in visitList) {
      for (let child of visitList[group]) {
        state.countVisits[child.id] = 0
        for (let visit of child.dates) {
          if (visit.status.indexOf('Ж') == -1) {
            state.countVisits[child.id] += 1
          }
        }
      }
    }
    state.visitList = visitList
  },

  SET_VISIT_ADDITIONAL(state, visitAdditionalList) {
    for (let group in visitAdditionalList) {
      state.countVisits[group] = {}
      for (let child of visitAdditionalList[group]) {
        state.countVisits[group][child.id] = 0
        for (let visit of child.dates) {
          if (visit.status.indexOf('О') == -1) {
            state.countVisits[group][child.id] += 1
          }
        }
      }
    }
    state.visitAdditionalList = visitAdditionalList
  },
  visit_additional_added(state, visit) {
    state.visitAdditionalList.push(visit)
  },
  auth_success(state, token) {
    state.status = 'success'
    state.token = token
  },
  logout(state) {
    state.status = ''
    state.token = ''
  },
  set_user(state, user) {
    state.user.id = user.id
    state.user.first_name = user.first_name
    state.user.last_name = user.last_name
    state.user.middle_name = user.middle_name
    state.user.birth_date = user.birth_date
    state.user.role = user.role
  },

  SCHEDULE_ADDED(state, schedule) {
    state.workersSchedulerList.push(schedule)
  },
  setSections(state, sections) {
    state.sections = sections.map(
      s => {
        let kind
        switch (s.kind) {
          case 'common':
            kind = 'Клуб'
            break
          case 'facultative':
            kind = 'Занятие'
            break
          default:
            kind = 'не обозначена'
        }
        return { ...s, kind_label: kind }
      }
    )
  },
  sectionAdded(state, section) {
    state.sections.push(section)
  },

  SET_REVIEW(state, reviews) {
    state.reviews = reviews
  },

  setReviewToEdit(state, review) {
    state.reviewToEdit = review
  },

  SET_CHILD_REPORT(state, report) {
    state.reportEdit = report
  },
}
