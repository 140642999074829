import axios from '@/libs/axios'
import router from '../../router'
import roleAbilities from '@/libs/acl/roleAbilities'

export default {
  getChildren({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'children/',
        method: 'GET',
        params: params,
      })
        .then((res) => {
          commit('SET_CHILDREN', res.data.results)
          commit('SET_CHILDREN_COUNT', res.data.count)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  addChildren({ commit }, children) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'children/',
        method: 'POST',
        data: children,
      })
        .then((res) => {
          commit('child_added', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },
  editChild({ commit }, child) {
    return new Promise((resolve, reject) => {
      if (child.exclude_from_email === 'no') child.exclude_from_email = false
      else child.exclude_from_email = true
      axios({
        url: `children/${child.id}/`,
        method: 'PATCH',
        data: child,
      })
        .then((res) => {
          commit('childEdited', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  getChildrenGroups({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'children-group/',
        method: 'GET',
      })
        .then((res) => {
          commit('SET_CHILDREN_GROUPS', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },
  addGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'children-group/',
        method: 'POST',
        data: data,
      })
        .then((res) => {
          commit('childrenGroupAdded', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },
  updateGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `children-group/${data.id}/`,
        method: 'PATCH',
        data: data,
      })
        .then((res) => {
          //commit("childrenGroupAdded", res.data);
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },
  deleteGroup({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `children-group/${id}/`,
        method: 'DELETE',
      })
        .then((res) => {
          commit('childrenGroupDeleted', id)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },
  deleteChild({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `children/${id}/`,
        method: 'DELETE',
      })
        .then((res) => {
          commit('childDeleted', id)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },
  getSections({ commit }, kind) {
    return new Promise((resolve, reject) => {
      axios({
        url: `section/?${kind ? ('kind=' + kind) : ''}`,
        method: 'GET',
      })
        .then((res) => {
          commit('setSections', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },
  addSection({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'section/',
        method: 'POST',
        data: data,
      })
        .then((res) => {
          commit('sectionAdded', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },
  deleteSection({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `section/${id}/`,
        method: 'DELETE',
      })
        .then((res) => {
          commit('sectionDeleted', id)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  getWorkers({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'workers/',
        method: 'GET',
        params: params,
      })
        .then((res) => {
          commit('SET_WORKERS', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },
  addWorker({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'workers/',
        method: 'POST',
        data: data,
      })
        .then((res) => {
          commit('workerAdded', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },
  inviteWorker({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `workers/${data.id}/invite`,
        method: "POST",
      }).then(
        res => {
          resolve(res)
        }
      ).catch(
        err => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ message: err })
        }
      )
    })
  },
  editWorker({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `workers/${data.id}/`,
        method: 'PATCH',
        data: data,
      })
        .then((res) => {
          commit('workerEdited', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  getWorkersScheduler({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'get_visits_workers/',
        method: 'GET',
        params: params,
      })
        .then((res) => {
          commit('SET_WORKERS_SCHEDULER', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },
  getListVisitScheduler({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'children-visit/',
        method: 'GET',
      })
        .then((res) => {
          commit('SET_VISIT_ADDITIONAL', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  getListVisitAdditionalScheduler({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'visit_scheduler_added/',
        method: 'GET',
      })
        .then((res) => {
          commit('SET_VISIT_ADDITIONAL_SCHEDULER', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  scheduleCreate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'schedule/',
        method: 'POST',
        data: data,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  listVisitAdditionalScheduleCreate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'children-visit-additional/',
        method: 'POST',
        data: data,
      })
        .then((res) => {
          // commit('visit_additional_added', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  listVisitAdditionalScheduleUpdate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `children-visit-additional/${data.id}/`,
        method: 'PATCH',
        data: data,
      })
        .then((res) => {
          // commit('visit_additional_added', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  listVisitScheduleCreate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'children-visit/',
        method: 'POST',
        data: data,
      })
        .then((res) => {
          // commit('visit_scheduler_added', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  listVisitScheduleUpdate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `children-visit/${data.id}/`,
        method: 'PATCH',
        data: data,
      })
        .then((res) => {
          // commit('visit_scheduler_added', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },
  getVisit({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'get_visits/',
        method: 'GET',
        params: params,
      })
        .then((res) => {
          commit('SET_VISIT', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  getReviews({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'reviews/',
        method: 'GET',
        params,
      })
        .then((res) => {
          commit('SET_REVIEW', res.data.results)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },
  addReviews({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'reviews/',
        method: 'POST',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  editReviews({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `reviews/${params.id}/`,
        method: 'PATCH',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  getReport({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `child_report/`,
        method: 'GET',
        params: params,
      })
        .then((res) => {
          commit('SET_CHILD_REPORT', { ...res.data, ...params })
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  getFiles({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `worker_files?user_id=${params.id}`,
        method: 'GET',
      })
        .then((res) => {
          commit('SET_WORKER_FILES', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  deleteFile({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `worker_files?file_id=${params.id}&user_id=${params.user_id}`,
        method: 'DELETE',
      })
        .then((res) => {
          commit('SET_WORKER_FILES', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  getChildBDays({ commit }, params) {
    console.log({ params })
    return new Promise((resolve, reject) => {
      axios({
        url: 'childs_bday/',
        method: 'GET',
        params: params,
      })
        .then((res) => {
          commit('SET_CHILDREN', res.data)
          commit('SET_CHILDREN_COUNT', res.data.length)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },
  // getChildBDaysByMonth({ commit }, params){
  //   return new Promise((resolve, reject) => {
  //     axios({
  //       url: "childs_bday/",
  //       method: "GET",
  //       params: params,
  //     })
  //       .then((res) => {
  //         commit("SET_CHILDREN", res.data);
  //         commit("SET_CHILDREN_COUNT", res.data.length);
  //         resolve(res);
  //       })
  //       .catch((error) => {
  //         reject({ message: error });
  //       });
  //   });
  // },

  getWorkerBDays({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'workers_bday/',
        method: 'GET',
        params: params,
      })
        .then((res) => {
          commit('SET_WORKERS', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  submitFiles({ commit }, params) {
    let formData = new FormData()
    for (var i = 0; i < params.files.length; i++) {
      let file = params.files[i]
      formData.append('files', file)
    }
    formData.append('user', params.user_id)

    return new Promise((resolve, reject) => {
      axios
        .post('worker_files/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          resolve(res)
          commit('APPEND_WORKER_FILES', res.data)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  sendReport({ commit }, params) {
    const fd = new FormData()

    Object.entries(params).forEach(
      ([k, v]) => {
        let v1 = v

        switch (k) {
          case 'stats':
          case 'reviews':
            v1 = JSON.stringify(v)
            break
          default:
            break
        }
        fd.append(k, v1)
      }
    )

    return new Promise((resolve, reject) => {
      axios.post('child_report/', fd)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  getVisitAdditional({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'get_visits_additional/',
        method: 'GET',
        params: params,
      })
        .then((res) => {
          commit('SET_VISIT_ADDITIONAL', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject({ message: error })
        })
    })
  },

  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'auth/token/',
        method: 'POST',
        data: user,
      })
        .then((response) => {
          const token = response.data.access
          const refreshToken = response.data.refresh
          localStorage.setItem('token', 'Bearer ' + token)
          localStorage.setItem('refresh_token', refreshToken)
          localStorage.setItem('role', response.data.role)
          localStorage.setItem('first_name', response.data.first_name)
          localStorage.setItem('last_name', response.data.last_name)
          localStorage.setItem('user_id', response.data.id)
          // localStorage.setItem('userData', "{\"id\":1,\"fullName\":\"John Doe\",\"username\":\"johndoe\",\"avatar\":\"/demo/vuexy-vuejs-admin-dashboard-template/demo-4/img/13-small.d796bffd.png\",\"email\":\"admin@demo.com\",\"role\":\"admin\",\"ability\":[{\"action\":\"manage\",\"subject\":\"all\"}],\"extras\":{\"eCommerceCartItemsCount\":5}}")

          let abilities = roleAbilities[response.data.role]

          localStorage.setItem(
            'user',
            JSON.stringify({
              id: response.data.id,
              fullName:
                response.data.last_name +
                ' ' +
                response.data.first_name +
                ' ' +
                response.data.middle_name,
              last_name: response.data.last_name,
              first_name: response.data.first_name,
              middle_name: response.data.middle_name,
              birth_date: response.data.birth_date,
              avatar:
                'demo/vuexy-vuejs-admin-dashboard-template/demo-4/img/13-small.d796bffd.png',
              email: 'admin@demo.com',
              role: response.data.role,
              username: 'admin',
              ability: abilities,
              extras: { eCommerceCartItemsCount: 5 },
            })
          )
          commit('auth_success', token)
          commit('set_user', response.data)
          resolve(response)
        })
        .catch((error) => {
          console.log('dsgs')
          reject({ message: error })
        })
    })
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      commit('logout')
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      delete axios.defaults.headers.common['Authorization']
      // router.push('/login')
      if (!router.currentRoute.path.endsWith('/login')) {
        router.push('/login')
      }
      resolve()
    })
  },
}
