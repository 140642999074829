export default {
  admin: [
    {
      action: 'read',
      subject: 'group',
    },
    {
      action: 'read',
      subject: 'section',
    },
    {
      action: 'read',
      subject: 'child',
    },
    {
      action: 'read',
      subject: 'visit'
    },
    {
      action: 'read',
      subject: 'additionalVisit'
    },
    {
      action: 'read',
      subject: 'commonVisit'
    },
    {
      action: 'read',
      subject: 'facultativeVisit'
    },
    {
      action: 'read',
      subject: 'employee'
    },
    {
      action: 'manage',
      subject: 'schedule'
    }
  ],
  director: [
    {
      action: 'manage',
      subject: 'all',
    }
  ],
  nurse: [
    {
      action: 'manage',
      subject: 'visit',
    }
  ],
  worker: [
    {
      action: 'read',
      subject: 'child',
    },
    {
      action: 'manage',
      subject: 'additionalVisit',
    },
    {
      action: 'manage',
      subject: 'commonVisit',
    },
    {
      action: 'manage',
      subject: 'facultativeVisit',
    },
    {
      action: 'create',
      subject: 'reviews',
    }
  ]
}
