import state from './moduleInfoState.js'
import mutations from './moduleInfoMutations.js'
import actions from './moduleInfoActions.js'
import getters from './moduleInfoGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
