import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import api from '@/libs/axios'

import router from './router'
import store from './store'
import App from './App.vue'

import Toast from 'vue-toastification'
import VueHtmlToPaper from 'vue-html-to-paper'
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'

const options = {
  // You can set your default options here
}
const VueInputMask = require('vue-inputmask').default
Vue.use(Toast, options)
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/acl/index'
// import { DocumentEditorPlugin } from '@syncfusion/ej2-vue-documenteditor'
// Vue.use(DocumentEditorPlugin)
import axios from '@/libs/axios'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueInputMask)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

//vue-html-to-paper
Vue.use(VueHtmlToPaper, {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://mycurelabs.github.io/vue-html-to-paper/landscape.css',
    '/app.css',
    '/app1.css',
    '/app2.css',
    '/app3.css',
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
})

Vue.config.productionTip = false

Vue.prototype.$http = api
api.defaults.timeout = 10000
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.common['Authorization'] = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      console.log('send me to login')
      store.dispatch('info/logout')
    }
    return Promise.reject(error)
  }
)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
