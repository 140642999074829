import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/ListChildren.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/reviews',
            name: 'reviews',
            component: () => import('@/views/ListReview.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/archive',
            name: 'archive',
            component: () => import('@/views/Archive.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/visit',
            name: 'visit',
            component: () => import('@/views/visit/ListVisit.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/visit-additional',
            name: 'visit-additional',
            component: () => import('@/views/visit/ListVisitAdditional.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/visit-common',
            name: 'visit-common',
            component: () => import('@/views/visit/ListCommon.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/visit-facultative',
            name: 'visit-facultative',
            component: () => import('@/views/visit/ListFacultative.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/workers',
            name: 'workers',
            component: () => import('@/views/workers/ListWorkers.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/groups',
            name: 'groups',
            component: () => import('@/views/groups/ListGroups.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/sections',
            name: 'sections',
            component: () => import('@/views/sections/ListSection.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/workers-schedule',
            name: 'worker-schedulers',
            component: () => import('@/views/workers/ListWorkersSchedule.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/second-page',
            name: 'second-page',
            component: () => import('@/views/SecondPage.vue'),
            meta: {
                pageTitle: 'Second Page',
                breadcrumb: [
                    {
                        text: 'Second Page',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (localStorage.getItem('token') != null) {
            next();
            return;
        }
        next("/login");
    } else {
        next();
    }
});

export default router
