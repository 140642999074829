export default {
  childrenList: [],
  reviews: [],
  workersList: [],
  workersSchedulerList: [],
  visitList: [],
  countVisits: {},
  visitAdditionalList: [],
  status: '',
  token: localStorage.getItem('token') || '',
  user: {},
  childrenGroups: [],
  sections: [],
  childToEdit: null,
  workerToEdit: null,
  reviewToEdit: null,
  reportEdit: null,
  childrenCount: 0,
  workerFiles: [],
}
